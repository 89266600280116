/**
 * PinImages is meant to offer an accessible way to change the pin images for result pin
 * on the interactive map page. Given some config, an SVG should be customizable to
 * have branding consistent styling in this file.
 */
class PinImages {
  /**
   * @param {Object} defaultPinConfig The configuration for the default pin
   * @param {Object} hoveredPinConfig The configuration for the hovered pin
   * @param {Object} selectedPinConfig The configuration for the selected pin
   */
  constructor(defaultPinConfig = {}, hoveredPinConfig = {}, selectedPinConfig = {}) {
    this.defaultPinConfig = defaultPinConfig;
    this.hoveredPinConfig = hoveredPinConfig;
    this.selectedPinConfig = selectedPinConfig;
  }

  /**
   * Generate standard theme pin given some parameters
   * @param {string} pin.backgroundColor Background color for the pin
   * @param {string} pin.strokeColor Stroke (border) color for the pin
   * @param {string} pin.labelColor Label (text) color for the pin
   * @param {number} pin.width The width of the pin
   * @param {number} pin.height The height of the pin
   * @param {string} pin.pinCount The index of the pin for the pin text
   * @return {Object} The SVG of the pin, and its width and height
   */
  generatePin ({
    backgroundColor = '#FFFFFF',
    strokeColor = 'black',
    labelColor = 'white',
    width = 20,
    height= 27,
    index = '',
    profile = ''
  } = {}) {
    const svg = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65.48 96.81"><defs><style>.cls-1{fill:#65459b;}.cls-2{fill:#fff;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Purple_Shirt" data-name="Purple Shirt"><path class="cls-1" d="M65.48,32.74A32.74,32.74,0,1,0,5.2,50.44L30.55,95.58a2.42,2.42,0,0,0,4.21,0L61.24,49a2.74,2.74,0,0,0,.15-.4A32.64,32.64,0,0,0,65.48,32.74Z"/><g id="Circles"><path class="cls-2" d="M51.09,52.74c.28-.26.57-.53.84-.81s.52-.53.78-.81a27.12,27.12,0,1,0-1.62,1.62M40,51.83A12.17,12.17,0,0,1,27.9,39.7,11.73,11.73,0,0,1,39.67,27.92,12.15,12.15,0,0,1,51.8,40.05a11.68,11.68,0,0,1-3.34,8.23l0,0-.06.08a.27.27,0,0,0-.08.07c-.19.19-.39.38-.58.55l.54-.52A11.68,11.68,0,0,1,40,51.83M27.92,27.94a16.87,16.87,0,0,0-1.28,22.42,17.38,17.38,0,0,1-2.25-1.88,16.75,16.75,0,0,1-4.92-11.91A17.09,17.09,0,0,1,36.54,19.49a16.76,16.76,0,0,1,11.91,4.92,17.25,17.25,0,0,1,1.88,2.25,16.87,16.87,0,0,0-22.41,1.28M17.11,17.11a22.13,22.13,0,0,1,31.29,0,23.08,23.08,0,0,1,2.33,2.75,21.92,21.92,0,0,0-31,30.82,22.62,22.62,0,0,1-2.65-2.26,22.13,22.13,0,0,1,0-31.29"/></g></g></g></svg>`;
    return { svg, width, height };
  };

  /**
   * Get the default pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getDefaultPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.defaultPinConfig.backgroundColor,
      strokeColor: this.defaultPinConfig.strokeColor,
      labelColor: this.defaultPinConfig.labelColor,
      width: 24,
      height: 28,
      index: '',
      profile: profile
    });
  }

  /**
   * Get the hovered pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getHoveredPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.hoveredPinConfig.backgroundColor,
      strokeColor: this.hoveredPinConfig.strokeColor,
      labelColor: this.hoveredPinConfig.labelColor,
      width: 24,
      height: 34,
      index: '',
      profile: profile
    });
  }

  /**
   * Get the selected pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getSelectedPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.selectedPinConfig.backgroundColor,
      strokeColor: this.selectedPinConfig.strokeColor,
      labelColor: this.selectedPinConfig.labelColor,
      width: 24,
      height: 34,
      index: '',
      profile: profile
    });
  }
}

export { PinImages };
